import { customColors } from 'constants/colors'
import { createTheme, Slider, ThemeProvider } from '@material-ui/core'

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GrClose } from 'react-icons/gr'
import { ImageMode } from 'components/ImageMode'

import { BiTimeFive, BiSun } from 'react-icons/bi'

export const Home = () => {
  const [selectedLevel, setSelectedLevel] = useState(3)
  const [showMenu, setShowMenu] = useState(false)

  const levels = [
    { idx: 0, blur: 5, label: 'Beginner' },
    { idx: 1, blur: 10, label: 'Intermediate' },
    { idx: 2, blur: 20, label: 'Advanced' },
    { idx: 3, blur: 40, label: 'Stoic' },
  ]

  const themeMaterial = createTheme({
    palette: {
      primary: {
        main: customColors.buttonBlue,
      },
    },
  })

  useEffect(() => {
    const storedLevel = localStorage.getItem('stoic')
    if (storedLevel) {
      const level = JSON.parse(storedLevel)
      if (typeof Number(level) === 'number') {
        console.log('level is', level)
        setSelectedLevel(Number(level))
      }
    }
  }, [showMenu])

  const handleClick = () => {
    setShowMenu(false)
    const storedLevel = selectedLevel
    localStorage.setItem('stoic', JSON.stringify(storedLevel))
  }

  return (
    <Wrapper>
      {!showMenu && (
        <GiHamburgerMenu
          size={32}
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            cursor: 'pointer',
            zIndex: 400,
          }}
          onClick={() => setShowMenu(true)}
        />
      )}
      {showMenu && (
        <>
          <SiteOverlay />
          <Popover>
            <GrClose
              size={24}
              color={'lightgrey'}
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                cursor: 'pointer',
                zIndex: 400,
              }}
              onClick={() => setShowMenu(false)}
            />
            <h3>How does it work?</h3>
            <PopoverRow>
              <BiTimeFive
                color={customColors.buttonBlue}
                style={{ fontSize: 24, margin: '0px 16px 24px 0px' }}
              />
              <p>You have to work with only one image a day.</p>
            </PopoverRow>
            <PopoverRow>
              <BiSun
                color={customColors.buttonBlue}
                style={{ fontSize: 28, margin: '0px 16px 24px 0px' }}
              />
              <p>Choose your blur level to focus on creativity.</p>
            </PopoverRow>
            <LevelPickerWrapper>
              <h3>Pick your blur level for today</h3>
              <SliderWrapper>
                <ThemeProvider theme={themeMaterial}>
                  <Slider
                    onChange={(e, val) => setSelectedLevel(val as number)}
                    aria-label="Small steps"
                    defaultValue={3}
                    value={selectedLevel}
                    step={1}
                    marks
                    min={0}
                    max={3}
                    valueLabelDisplay="off"
                  />
                </ThemeProvider>
              </SliderWrapper>
              <Legend>
                <h5>Beginner</h5>
                <h5>Stoic</h5>
              </Legend>
            </LevelPickerWrapper>
            <SignMeUp onClick={() => handleClick()}>
              <h4 style={{ color: 'white' }}>Let's go</h4>
            </SignMeUp>
          </Popover>
        </>
      )}
      <OverlayWrapper>
        <ImageMode blurVal={levels[selectedLevel].blur} />
      </OverlayWrapper>
    </Wrapper>
  )
}

const SiteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 100;
  opacity: 0.4;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const OverlayWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LevelPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  margin: 32px 0px 16px;
`
const SignMeUp = styled.button`
  display: flex;
  flex: 0 0 40px;
  width: 100%;
  max-width: 160px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  background: ${customColors.buttonBlue};
  border: none;
`

const PopoverRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`

const Popover = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  padding: 48px 24px;
  background-color: white;
  max-width: 480px;
  z-index: 100;
  border-radius: 6px;
  gap: 8px;
  margin: 16px;
  overflow-y: scroll;
  align-items: center;
`

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 24px;
`
