export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const MOBILE_BREAKPOINT_N = 800
export const MOBILE_BREAKPOINT = `${MOBILE_BREAKPOINT_N}px`
export const MOBILE_STYLE = `@media (max-width: ${MOBILE_BREAKPOINT})`
export const DESKTOP_STYLE = `@media (min-width: ${MOBILE_BREAKPOINT})`

export const videoLinks = [
  'https://www.xnxx.com/embedframe/72013222',
  'https://www.xnxx.com/embedframe/71980676',
  'https://www.xnxx.com/embedframe/71997378',
  'https://www.xnxx.com/embedframe/71735988',
  'https://www.xnxx.com/embedframe/71980308',
  'https://www.xnxx.com/embedframe/71948830',
  'https://www.xnxx.com/embedframe/72013402',
  'https://www.xnxx.com/embedframe/71753164',
  'https://www.xnxx.com/embedframe/71757568',
  'https://www.xnxx.com/embedframe/71752880',
  'https://www.xnxx.com/embedframe/71838571',
  'https://www.xnxx.com/embedframe/71206881',
  'https://www.xnxx.com/embedframe/71489665',
  'https://www.xnxx.com/embedframe/71710374',
  'https://www.xnxx.com/embedframe/70870641',
  'https://www.xnxx.com/embedframe/71608861',
  'https://www.xnxx.com/embedframe/71708720',
  'https://www.xnxx.com/embedframe/69659207',
  'https://www.xnxx.com/embedframe/68025931',
  'https://www.xnxx.com/embedframe/67703613',
  'https://www.xnxx.com/embedframe/68045457',
  'https://www.xnxx.com/embedframe/66920139',
  'https://www.xnxx.com/embedframe/66661119',
  'https://www.xnxx.com/embedframe/55866759',
  'https://www.xnxx.com/embedframe/71017947',
  'https://www.xnxx.com/embedframe/71833891',
  'https://www.xnxx.com/embedframe/71822045',
  'https://www.xnxx.com/embedframe/72252438',
  'https://www.xnxx.com/embedframe/72052076',
  'https://www.xnxx.com/embedframe/71578309',
  'https://www.xnxx.com/embedframe/68045457',
  'https://www.xnxx.com/embedframe/66920139',
]
