import {
  AppThemeContext,
  configureAppTheme,
} from '@foronered/web/lib/theme/theme'
import { Home } from 'pages/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Scaffold } from 'Scaffold'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
  }

  #root {
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding:0;
    background-color: white;
  }

  h1, h2, h3, h4, h5, p, a , li{
    font-family: 'helvetica';
    margin: 0;
    text-decoration: none;
    color: black;
  }

  p {
    line-height: 1.5;
  }

  * {
    box-sizing: border-box;
  }
`

const appTheme = configureAppTheme({
  button: {
    chromeless: {},
    secondary: {},
    common: {
      backgroundColor: 'red',
      color: 'white',
    },
  },
})

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <AppThemeContext.Provider value={appTheme}>
        <BrowserRouter>
          <Scaffold>
            <Routes>
              <Route path={'/'} element={<Home />} />
              {/* <Route path={'/angebot'} element={<Angebot />} />
              <Route path={'/ueber-uns'} element={<UeberUns />} />
              <Route path={'/kontakt'} element={<Kontakt />} /> */}
            </Routes>
          </Scaffold>
        </BrowserRouter>
      </AppThemeContext.Provider>
    </>
  )
}
