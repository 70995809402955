import styled from 'styled-components'

// number from 1 to 31
const date = new Date()

const aggregated = date.getDate() * date.getDay() * date.getFullYear()

console.log(aggregated % 162)

console.log(aggregated)

const pic = (aggregated % 161) + 1

export const ImageMode = (props: { blurVal: number }) => {
  return (
    <>
      <Overlay />
      <ImgWrapper blurVal={props.blurVal} src={`pics/${pic}.jpg`} />
    </>
  )
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
`

const ImgWrapper = styled.img<{
  blurVal: number
}>`
  display: flex;
  width: 100%;
  max-width: 620px;
  max-height: 100%;
  object-fit: contain;
  -webkit-filter: ${({ blurVal }) => `blur(${blurVal}px)`};
  -moz-filter: ${({ blurVal }) => `blur(${blurVal}px)`};
  -o-filter: ${({ blurVal }) => `blur(${blurVal}px)`};
  -ms-filter: ${({ blurVal }) => `blur(${blurVal}px)`};
  filter: ${({ blurVal }) => `blur(${blurVal}px)`};
`
