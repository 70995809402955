import { MOBILE_BREAKPOINT_N } from 'constants/variables'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

export const useIsMobile = (): boolean => {
  const dataView = useContext(IsMobile)
  return dataView
}

const IsMobile = createContext<boolean>(false)

IsMobile.displayName = 'IsMobile'

export const Scaffold = (props: { children: ReactNode }) => {
  // const menuItems = [
  //   { id: '', label: 'Home', color: customColors.pastelPurple },
  //   { id: 'angebot', label: 'Angebot', color: customColors.pastelGreen },
  //   { id: 'ueber-uns', label: 'Über uns', color: customColors.pastelBlue },
  //   // { id: 'kontakt', label: 'Kontakt', color: customColors.pastelRed },
  // ]

  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT_N)
    }

    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT_N)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <IsMobile.Provider value={!!isMobile}>
      <Wrapper>{props.children}</Wrapper>
    </IsMobile.Provider>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`
